import { Link } from "gatsby";
import React from "react";
import BlogPostPreview from "./blog-post-preview";

import styles from "./blog-post-preview-grid.module.css";

function BlogPostPreviewGrid({ nodes }) {
  return (
    <div className={styles.root}>
        <ul className={styles.grid}>
          {nodes &&
            nodes.map(node => (
              <li key={node.id}>
                <BlogPostPreview {...node} />
              </li>
            ))}
        </ul>
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogPostPreviewGrid;
